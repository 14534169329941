import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import PrivateRoutes from "../Utils/PrivateRoutes";
import { isOnlyForBAU } from "../Utils/utils";
import PageTitleChanger from "./PageTitleChanger";
import { getEnvironment } from "../../../shared/utils/EnvConfig";

window.chatParameters = window?.chatParameters || {
  debug: false,
  jwthost: "",
  ccaiphost: "",
  companyID: "",
  menuKey: ""
};



const DashBordHomePage = React.lazy(() => import("../Pages/Home/home"));
const AccountSettingPage = React.lazy(() => import("../Pages/accountSetting"));
const Payments = React.lazy(() => import("../Pages/Payments"));
const Network = React.lazy(() => import("../Pages/Network"));
const Appointment = React.lazy(() => import("../Pages/appointment"));
const HelpAndSupport = React.lazy(() => import("../Pages/helpAndSupport"));
const ACPForm = React.lazy(() => import("../Pages/ACPForm"));
const AddNewAccountPage = React.lazy(
  () => import("../Pages/addnewAccount/addnewaccount")
);
const AccountFoundVerificationCode = React.lazy(
  () => import("../Pages/addnewAccount/accountFoundVerificationCode")
);
const AddNewAccountSuccess = React.lazy(
  () => import("../Pages/addnewAccount/addaccountsuccess")
);
const QuickBillPayments = React.lazy(
  () => import("../Pages/quickBillPayments/QuickBillPayments/QuickBillPayments")
);

const AgentQBP = React.lazy(() => import("../Pages/agentQuickBillPay"));
const NonAuthQBP = React.lazy(
  () => import("../Pages/quickBillPayments/NonAuthenticatedQuickBillPay/index")
);

const PauseService = React.lazy(
  () => import("../Pages/pauseService/PauseService")
);
const LoginPageWithLatestDeisgn = React.lazy(
  () => import("../Pages/LogReg/loginPage")
);
const ForgetUserName = React.lazy(
  () => import("../Pages/LogReg/forgetUserName")
);
const ForgetUserNameError = React.lazy(
  () => import("../Pages/LogReg/forgetUserNameError")
);
const ForgotUserNameReminderPage = React.lazy(
  () => import("../Pages/LogReg/ForgotUserNameReminder")
);
const LoginPasswordPage = React.lazy(
  () => import("../Pages/LogReg/ForgotPasswordPhoneNumber")
);
const ForgotPassword = React.lazy(
  () => import("../Pages/LogReg/ForgotPassword")
);
const ForgotPasswordSendMesgDetails = React.lazy(
  () => import("../Pages/LogReg/ForgotPasswordSendLink")
);
const AccountFoundWithEmailNPhone = React.lazy(
  () => import("../Pages/addnewAccount/accountFoundWithEmailNPhone")
);
const ForgotPasswordVerificationCodePage = React.lazy(
  () => import("../Pages/LogReg/ForgotPasswordVerificationCode")
);
const ResetPasswordNewPage = React.lazy(
  () => import("../Pages/LogReg/ResetPasswordNew")
);
const ResetPasswordSuccessPage = React.lazy(
  () => import("../Pages/LogReg/ResetPasswordReminder")
);
const SignUpEmailPage = React.lazy(() => import("../Pages/LogReg/SignUpEmail"));
const SignUpPasswordPage = React.lazy(
  () => import("../Pages/LogReg/SignUpPassword")
);
const SignUpCompleted = React.lazy(() => import("../Pages/LogReg/SignUpAlert"));

const MultipleAccountsRegisterationPage = React.lazy(
  () => import("../Pages/LogReg/MultipleAccountsRegisteration")
);

const RescheduleAppointmentForOthersPlatform = React.lazy(
  () => import("../Pages/rescheduleAppointmentForOthers")
);
const LoginServiceAddressPage = React.lazy(
  () => import("../Pages/LogReg/loginServiceAddress")
);

const LoginServiceEmailPage = React.lazy(
  () => import("../Pages/LogReg/loginServiceEmail")
);
const LoginServiceAddressNoCaptchaPage = React.lazy(
  () => import("../Pages/LogReg/loginServiceAddressNoCaptcha")
);
const LoginServiceAddressError = React.lazy(
  () => import("../Pages/LogReg/loginServiceAddressError")
);
const ShopExperience = React.lazy(
  () => import("../Pages/accountSetting/shop-experience")
);
const ChangePlanForAgent = React.lazy(
  () => import("../Pages/changePlanForAgent/index")
);
const AccountNotFound = React.lazy(
  () => import("../Pages/LogReg/accountNotVerify")
);
const PaymentArrangements = React.lazy(
  () => import("../Pages/paymentArrangements/index")
);
const AgentPaymentArrangement = React.lazy(
  () => import("../Pages/paymentArrangements/non-authPayArrangements")
);
const SendOTP = React.lazy(
  () =>
    import(
      "../Pages/quickBillPayments/NonAuthenticatedQuickBillPay/SendOTP-NonAuth"
    )
);
const VerifyOTP = React.lazy(
  () =>
    import(
      "../Pages/quickBillPayments/NonAuthenticatedQuickBillPay/VerifyOTP-NonAuth"
    )
);



const AllRoutes = () => {
  const checkIsOnlyForBAU = isOnlyForBAU();
  const location = useLocation();
  useEffect(() => {
    const env=getEnvironment()
    const scEl = document.createElement('script');
    scEl.src = `${env.chatstartscriptSrc}`;  // Ensure correct file extension
    scEl.async = true;
    scEl.onload = () => {
      console.log('Script loaded successfully');
      window.chatParameters = window.chatParameters || {};
      window.chatParameters.jwthost = env.chatstartscriptChatparamJwthost;
      window.chatParameters.ccaiphost = env.chatstartscriptChatparamCCaiphost;
      window.chatParameters.companyID = env.chatstartscriptChatparamCompanyID;
      window.chatParameters.menuKey = env.chatstartscriptChatparamMenuKey;
      console.log('chat params', window.chatParameters);
      const chatDev=document.getElementById('chatLauncher');
    };
  
    scEl.onerror = () => {
      console.error('chat start script not loading');
    };
    document.head.appendChild(scEl);
    const script = document.createElement('script');
    script.src = `${env.chatControlscriptSrc}`;
    script.async = true;
    script.onerror = () => {
      console.error('chat control script not loading');
    };
    document.head.appendChild(script);
  
    return () => {
      document.head.removeChild(scEl);
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
    <PageTitleChanger/>
    <Routes>
      <Route element={<PrivateRoutes />}>
        {/* private or protected routes be here */}
        <Route element={<ACPForm />} path="/acpform" />
        <Route
          element={<AccountSettingPage />}
          path="/account-settings/:activeTab"
        />
        <Route element={<DashBordHomePage />} path="/home/:step" />
        <Route element={<DashBordHomePage hideStapper={true} />} path="/home" />
        <Route element={<Payments />} path="/my-bills/:paymentOption" />
        {!checkIsOnlyForBAU && <Route
          element={<QuickBillPayments />}
          path="/my-bills/quick-bill-payments"
        />}
        <Route element={<Appointment />} path="/appointments" />
        <Route element={<PauseService />} path="/pause-service" />
        <Route
          element={<Appointment />}
          path="/appointments/current/:orderNumber"
        />
        <Route element={<HelpAndSupport />} path="/help-and-support" />
        <Route element={<ShopExperience />} path="/update-plan" />
        {!checkIsOnlyForBAU && <Route element={<Network />} path="/network-settings/:networkOptions" />}
        {!checkIsOnlyForBAU && <Route element={<AddNewAccountPage />} path="/add-new-account" />}
        {!checkIsOnlyForBAU && <Route
          element={<AccountFoundWithEmailNPhone />}
          path="/account-found"
        />}
        {!checkIsOnlyForBAU && <Route
          element={<AddNewAccountSuccess />}
          path="/account-successfully-Added"
        />}
        {!checkIsOnlyForBAU && <Route
          element={<AccountFoundVerificationCode />}
          path="/account-verification"
        />}
      </Route>

      {/* public or un-protected routes be here */}
      <Route element={<LoginPageWithLatestDeisgn />} path="/login" />
      <Route element={<ForgetUserName />} path="/new-forget-username" />
      <Route element={<ForgetUserNameError />} path="/forget-username-error" />
      <Route
        element={<ForgotUserNameReminderPage />}
        path="/new-forget-username-sent-reminder"
      />
      <Route element={<LoginPasswordPage />} path="/login-password" />
      <Route element={<ForgotPassword />} path="/new-forget-password" />
      <Route
        element={<ForgotPasswordSendMesgDetails fromRegister={false} />}
        path="/new-forget-password-send-msg-detail"
      />
      <Route
        element={<ForgotPasswordVerificationCodePage fromRegister={false} />}
        path="/new-forget-password-otp"
      />
      <Route
        element={<ForgotPasswordVerificationCodePage fromRegister={true} />}
        path="/signUp-password-otp"
      />
      <Route element={<ResetPasswordNewPage />} path="/new-reset-password" />
      <Route
        element={<ResetPasswordSuccessPage />}
        path="/new-reset-password-confirm"
      />
      <Route element={<SignUpEmailPage />} path="/signUp" />
      <Route
        element={<ForgotPasswordSendMesgDetails fromRegister={true} />}
        path="/signUp-send-msg-detail"
      />
      <Route
        element={<SignUpPasswordPage fromSetPass={false} />}
        path="/signUp-password"
      />
      <Route
        element={<SignUpCompleted fromSetPass={false} />}
        path="/signUp-confirm"
      />

      <Route
        element={<SignUpPasswordPage fromSetPass={true} />}
        path="/set-password"
      />
      <Route
        element={<SignUpCompleted fromSetPass={true} />}
        path="/reset-password-confirm"
      />

      <Route
        element={<RescheduleAppointmentForOthersPlatform />}
        path="/reschedule"
      />
      <Route element={<PauseService />} path="/pause-service-external" />

      <Route element={<LoginServiceAddressPage />} path="/uni-login" />
      <Route element={<LoginServiceEmailPage />} path="/uni-login-email" />

      <Route
        element={<LoginServiceAddressNoCaptchaPage />}
        path="/uni-login-nocaptcha"
      />

      <Route
        element={<LoginServiceAddressError />}
        path="/uni-login-no-match"
      />
      <Route
        element={
          <ForgotPasswordSendMesgDetails
            fromRegister={true}
            fromValidateLogin={true}
          />
        }
        path="/validate-login"
      />
      <Route
        element={
          <ForgotPasswordVerificationCodePage
            fromRegister={true}
            fromValidateLogin={true}
          />
        }
        path="/validate-login-otp"
      />
      <Route
        element={
          <ForgotPasswordSendMesgDetails
            fromRegister={true}
            fromValidateLogin={true}
            fromFirstTimeSetPass={true}
          />
        }
        path="/set-password-validate"
      />

      <Route
        element={
          <ForgotPasswordVerificationCodePage
            fromRegister={true}
            fromValidateLogin={true}
            fromFirstTimeSetPass={true}
          />
        }
        path="/set-password-validate-otp"
      />

      <Route element={<ChangePlanForAgent />} path="/change-plan" />
      <Route element={<ShopExperience />} path="/shop-exp-agent" />

      <Route element={<LoginPageWithLatestDeisgn />} path="*" />
      {!checkIsOnlyForBAU && <Route element={<AccountNotFound />} path="/unable-to-verify" />}
      {!checkIsOnlyForBAU && <Route element={<AgentQBP />} path="/agent-quick-bill-pay" />}
      {!checkIsOnlyForBAU && <Route
        element={<AgentPaymentArrangement />}
        path="/agent-payment-arrangements"
      />}
      {!checkIsOnlyForBAU && <Route
        element={<NonAuthQBP flow="NON_AUTH_QBP" />}
        path="/non-auth-quick-bill-pay"
      />}
      {!checkIsOnlyForBAU && <Route
        element={<NonAuthQBP flow="NON_AUTH_PAYMENT_ARRANGEMENTS" />}
        path="/non-auth-payment-arrangement"
      />}
      {!checkIsOnlyForBAU && <Route element={<SendOTP />} path="/non-auth-validation" />}
      {!checkIsOnlyForBAU && <Route element={<VerifyOTP />} path="/non-auth-verify-otp" />}
    </Routes>
    </>
  );
};

export default AllRoutes;
