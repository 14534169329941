import ApiService from "../utils/ApiService";
import { getEnvironment } from "../utils/EnvConfig";

// It's for the fetching the  content from the AEM content stack
const useFetchAEMContent = async (params = {}, headers) => {
  const env = getEnvironment();
  return await ApiService.get(
    { ...params, apiUrl: env.aem_content_url },
    { headers: headers }
  );
};
export default useFetchAEMContent;
