import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import fetchContent from "../../../../shared/hooks/useFetchContent";
import {
  LAYOUT_CONTENT,
  contentApiHeader,
  contentApiParams,
} from "../../../../shared/utils/Constants";
import { errorConditionGTM } from "../../Utils/googleTags";

type contentDetailsInitialStateType = {
  layoutContent: any;
  loading: boolean;
  error: any;
};
const initialState: contentDetailsInitialStateType = {
  layoutContent: null,
  loading: false,
  error: null,
};

export const getLayoutContent = createAsyncThunk(
  "get/layout/content",
  async () => {
    try {
      const response = await fetchContent(
        contentApiParams(LAYOUT_CONTENT),
        contentApiHeader
      );
      return response?.data?.entry;
    } catch (error) {
      throw error;
    }
  }
);

const layoutContentDetailSlice = createSlice({
  name: "layout/content/Details",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLayoutContent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLayoutContent.fulfilled, (state, action) => {
      state.layoutContent = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getLayoutContent.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "layout contentstack",
        action.error?.message,
        action?.type
      );
      state.layoutContent = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default layoutContentDetailSlice.reducer;
