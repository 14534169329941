import ApiService from "../utils/ApiService";
import { getEnvironment } from "../utils/EnvConfig";

export const getNonAuthPaymentArrangements = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.post(
    {
      ...params,
      apiUrl: env.brightspeedApi,
    },
    body,
    { headers: headers }
  );
};
