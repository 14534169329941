import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { fetchPaymentMethodDetails, addAutoPayEnroll, removeAutoPayEnroll } from "../../../../shared/hooks/useFetchPayment";
import { v4 as uuidv4 } from "uuid";
import API_URLS from "../../../../shared/utils/ApiUrls";
import { errorConditionGTM } from "../../Utils/googleTags";
import store from "../store";

type singlePaymentMethodsDetailsInitialStateType = {
  singlePaymentMethodsDetails: any;
  loading: boolean;
  error: any;
  singlePaymentLoading:boolean;
  enrollPaymentsLoading:boolean;
  enrollPaymentsError:any;
  paymentMethodListCC:any;
  paymentMethodListACH:any;
  defaultPayId:any;
  deletePaymentDetails:any;
  nextPrimaryPaymnetId: any;
  nextPrimaryMethod:any;
  defaultPayMethod:any;
  popupDetails:any;
  enrollResultSet:any;
  removeAutoPayLoading:boolean;
  removeAutoPayError: any;
  removeAutoPayResult:any;
  isManageAutoPay: boolean;

};

const initialState: singlePaymentMethodsDetailsInitialStateType = {
  singlePaymentMethodsDetails: null,
  loading: false,
  error: null,
  singlePaymentLoading: false,
  enrollPaymentsLoading: false,
  enrollPaymentsError: null,
  paymentMethodListCC:null,
  paymentMethodListACH:null,
  defaultPayId:null,
  deletePaymentDetails:null,
  nextPrimaryPaymnetId:null,
  nextPrimaryMethod:null,
  defaultPayMethod:null,
  popupDetails:null,
  enrollResultSet:null,
  removeAutoPayLoading:false,
  removeAutoPayError:null,
  removeAutoPayResult:null,
  isManageAutoPay:false,
};

export const getSinglePaymentMethodDetails = createAsyncThunk(
  "single/paymentMethod/details",
  async (cardId: string) => {
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const response = await fetchPaymentMethodDetails(
        {
          [API_URLS?.addUpdatePaymentMethodUrl_QA]: cardId,
        },
        headers
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const enrollPaymentMethod = createAsyncThunk(
  "single/paymentMethod/enroll",
  async (body: any) => {
    try {
      const reduxStore = store.getState();
      const ban = reduxStore?.user?.data?.billingAccountNumber;
      const headers = reduxStore?.brightspeedToken?.headers;
      const response = await addAutoPayEnroll(
        {
          [API_URLS?.enrollAutoPay]: ban,
        },
        body,
        headers
      );
      return response; 
    } catch (error) {
      throw error;
    }
  }
);

export const removeAutopay = createAsyncThunk(
  "single/paymentMethod/removeautopay",
  async (billingAccountNumber: any) => {
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const payload = {
        baseline: {
          transactionId: uuidv4().slice(0, 21),
          partnerId: "BS196123",
          salesCode: "20000660",
          referenceId: "",
          orderId: "",
          agentId: "",
          channel: "dotcom",
          siteId: headers?.siteId
        },
        banNumber: billingAccountNumber
      }
      const response = await removeAutoPayEnroll(
        {
          "": `${API_URLS?.remove_autopay}`,
        },
        payload,
        headers
      );
      return response; 
    } catch (error) {
      throw error;
    }
  }
);





const singlePaymentMethodDetailSlice = createSlice({
  name: "single/paymentMethod",
  initialState,
  reducers: {
    setAddPaymentMethod: (state, action: PayloadAction<boolean>) => {
      state.singlePaymentMethodsDetails = action.payload;
    },
    setSinglePaymentloader :(state, action: PayloadAction<boolean>)=> {
      state.singlePaymentLoading = action.payload;
    },
    singlePaymentMethodDetailSliceSetInitialState: () => initialState,

    setDeletepayment: (state, action:PayloadAction<any>)=>{
      state.deletePaymentDetails = action.payload;
    },
    setPaymentListCC: (state, action:PayloadAction<any>)=>{
      state.paymentMethodListCC = action.payload;
    },
    setPaymentListACH: (state, action:PayloadAction<any>)=>{
      state.paymentMethodListACH = action.payload;
    },
    setDefaultPaymentId: (state, action:PayloadAction<any>)=>{
      state.defaultPayId = action.payload;
    },
    setNextPrimaryMethod:(state, action:PayloadAction<any>)=>{ 
      state.nextPrimaryMethod = action.payload;
    },
    setNextPrimaryPaymnetId:(state, action:PayloadAction<any>)=>{
      state.nextPrimaryPaymnetId = action.payload;
    },
    setDefaultPayment: (state, action:PayloadAction<any>)=>{
      state.defaultPayMethod = action.payload;
    },
    setPopupDetails:(state, action:PayloadAction<any>)=>{
      state.popupDetails = action.payload;
    },
    setEnrollDataSet:(state,action:PayloadAction<any>)=>{
      state.enrollResultSet = action.payload;
    },
    setManageAutoPayState:(state,action:PayloadAction<boolean>)=>{
      state.isManageAutoPay=action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSinglePaymentMethodDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getSinglePaymentMethodDetails.fulfilled,
      (state, action) => {
        state.singlePaymentMethodsDetails = action.payload;
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(getSinglePaymentMethodDetails.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "payment method by id",
        action.error?.message,
        action?.type
      );
      state.singlePaymentMethodsDetails = null;
      state.loading = false;
      state.error = action.error;
    });

    builder.addCase(enrollPaymentMethod.pending,(state)=>{
      state.enrollPaymentsLoading=true;
      
    });
    builder.addCase(enrollPaymentMethod.fulfilled,(state, action)=>{
        state.enrollResultSet = action.payload;
      state.enrollPaymentsLoading= false;
      state.enrollPaymentsError= null;
    });
    builder.addCase(enrollPaymentMethod.rejected,(state, action)=>{
      
      state.enrollPaymentsLoading= false;
      state.enrollPaymentsError= action.error;
    });

    builder.addCase(removeAutopay.pending, (state) => {
      state.removeAutoPayLoading = true;
    });
    builder.addCase(removeAutopay.fulfilled, (state, action) => {
      state.removeAutoPayResult = action.payload;
      state.removeAutoPayLoading = false;
      state.removeAutoPayError = null;
    });
    builder.addCase(removeAutopay.rejected, (state, action) => {
      state.removeAutoPayLoading = false;
      state.removeAutoPayError = action.error;
    });
    
  },
});

export const {
  singlePaymentMethodDetailSliceSetInitialState,
  setAddPaymentMethod, setSinglePaymentloader,
  setDeletepayment,
  setDefaultPaymentId,
  setPaymentListACH,
  setPaymentListCC,
  setNextPrimaryMethod,
  setNextPrimaryPaymnetId,
  setDefaultPayment, 
  setPopupDetails,
  setEnrollDataSet,
  setManageAutoPayState,
} = singlePaymentMethodDetailSlice.actions;
export default singlePaymentMethodDetailSlice.reducer;
