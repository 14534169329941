import { useState, useEffect } from "react";
import ApiService from "../utils/ApiService";
import Util from "../utils/Util";
import { savePaymentData } from "../actions/DashboardActions";
import { getEnvironment } from "../utils/EnvConfig";
const store = Util.configureStore();
const useFetchPayment = (params = {}, header = {}) => {
  const [data, setData] = useState(null);
  let isMountedScreen = true;
  useEffect(() => {
    getResponse();
    return () => {
      isMountedScreen = false;
    };
  }, []);
  const getResponse = async () => {
    const env = getEnvironment();
    ApiService.get(
      { ...params, apiUrl: env.brightspeedApi },
      { headers: header }
    ).then((result) => {
      if (isMountedScreen) {
        setData(result.data);
        store.dispatch(savePaymentData(result.data));
      }
    });
  };
  const reload = async () => {
    await getResponse();
  };
  return [data, reload];
};

// return promise for invoice
export const fetchInVoiceDetails = async (params = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.get(
    { ...params, apiUrl: env.brightspeedApi },
    { headers: headers }
  );
};

// return promise for get single payment method
export const fetchPaymentMethodDetails = async (params = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.get(
    { ...params, apiUrl: env.brightspeedApi },
    { headers: headers }
  );
};

export const addPaymentMethod = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.brightspeedApi },
    body,
    { headers: headers }
  );
};

export const submitInstallPaymentDate = async (params = {},body={},headers ={}) => {
  const env = getEnvironment();
  return await ApiService.post({ ...params, apiUrl: env.brightspeedApi },
       body,
      {headers:headers})

};

export const forgotUserCheck = async (params={}, body={}, headers={}) => {
  const env = getEnvironment();
  return await ApiService.post({...params,apiUrl: env.brightspeedApi },
    body,
    {headers: headers}
  );

};

// return promise for getting payment token
export const paymentGenerateToken = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.brightspeedApi },
    body,
    { headers: headers }
  );
};

// return promise for getting payment signature
export const paymentGenerateSignature = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.brightspeedApi },
    body,
    { headers: headers }
  );
};

export const updatePaymentMethod = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.put({ ...params, apiUrl: env.brightspeedApi }, body, {
    headers: headers,
  });
};

export const deletePaymentMethod = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.delete(
    { ...params, apiUrl: env.brightspeedApi },
    { headers: headers }
  );
};

export const makePaymentMethodDefault = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.put({ ...params, apiUrl: env.brightspeedApi }, body, {
    headers: headers,
  });
};

//To fetch the payment history
export const useFetchPaymentHistory = (params = {}) => {
  const [data, setData] = useState(null);
  let isMountedScreen = true;
  useEffect(() => {
    getResponse();

    return () => {
      isMountedScreen = false;
    };
  }, []);
  const getResponse = async () => {
    const env = getEnvironment();
    ApiService.get({ ...params, apiUrl: env.brightspeedApi }, {}, {}).then(
      (result) => {
        if (isMountedScreen) {
          setData(result.data);
        }
      }
    );
  };
  return [data];
};

//To fetch the list of already added payment methods
export const useFetchPaymentMethod = (params = {}, header = {}) => {
  const [data, setData] = useState(null);
  let isMountedScreen = true;
  useEffect(() => {
    getResponse();
    return () => {
      isMountedScreen = false;
    };
  }, []);
  const getResponse = async () => {
    const env = getEnvironment();
    ApiService.get(
      { ...params, apiUrl: env.brightspeedApi },
      { headers: header }
    ).then((result) => {
      if (isMountedScreen) {
        setData(result.data);
      }
    });
  };
  const reload = async () => {
    await getResponse();
  };
  return [data, reload];
};

export const useFetchInvoiceDetails = (params = {}, header = {}) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    getResponse();
  }, []);
  const getResponse = async () => {
    const env = getEnvironment();
    ApiService.get(
      { ...params, apiUrl: env.brightspeedApi },
      { headers: header }
    ).then((result) => {
      setData(result.data);
    });
  };
  return [data];
};

export const purlPayment = async (params = {}, body = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.brightspeedApi },
    body,
    {
      headers: headers,
    }
  );
};
/* Below Code added by Ankit */
export const creditCardVerificationMethod = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.brightspeedApi },
    body,
    {
      headers: headers,
    }
  );
};

export const makeQuickPaymentMethod = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.brightspeedApi },
    body,
    {
      headers: headers,
    }
  );
};

export const submitAutoPaymentMethod = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.brightspeedApi },
    body,
    {
      headers: headers,
    }
  );
};

export const addAutoPayEnroll = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.put({ ...params, apiUrl: env.brightspeedApi }, body, {
    headers: headers,
  });
};

export const removeAutoPayEnroll = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.brightspeedApi },
    body,
    {
      headers: headers,
    }
  );
};
export const updateAuotPayEnroll = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.brightspeedApi },
    body,
    {
      headers: headers,
    }
  );
};
export default useFetchPayment;
