import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getRepairAppointment } from "../../../../shared/hooks/useFetchUpcomingAppointment";
import API_URLS from "../../../../shared/utils/ApiUrls";
import { checkIsUpcommingAppointment } from "../../../../shared/utils/dateUtils";
import { errorConditionGTM } from "../../Utils/googleTags";
import store from "../store";

type repairAppointmentInitialStateType = {
  repairAppointmentDetails: any;
  loading: boolean;
  error: any;
};
const initialState: repairAppointmentInitialStateType = {
  repairAppointmentDetails: null,
  loading: false,
  error: null,
};

export const getRepairAppointmentDetails = createAsyncThunk(
  "repairAppointment/details",
  async (billingAccountId: string) => {
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const response = await getRepairAppointment(
        {
          "": API_URLS?.repairAppointmentURL,
          // "": `${API_URLS?.repairAppointmentURL}${"200005647"}`,
        },
        {
          baseline: {
            transactionId:
              reduxStore?.addressQualification?.data?.addressQualification
                ?.entity?.partnerSessionId || "",
            channel: "web-acm",
            wirecenter: "HNVLGAXA",
            siteId: headers?.siteId || "ctl",
          },
          ban: billingAccountId,
        },
        headers
      );

      if (response?.data?.result?.tickets?.open?.length > 0) {
        const data = response?.data?.result?.tickets?.open?.sort(
          (a: any, b: any) =>
            new Date(b?.created_on)?.valueOf() -
            new Date(a?.created_on).valueOf()
        );
        return {
          result: data?.map((item: any) => {
            return {
              u_appointment_end: item?.appointment_end, //"03/12/2024 17:00",
              number: item?.number,
              u_technician_name: item?.technician_name || "",
              u_appointment_start: item?.appointment_start,
              u_reservation_number: item?.reservation_id,
              // u_appointment: "ccdd93c71bf4ce10cedec998624bcb47",
              u_service_instance_id: item?.service_instance_id,
              u_appointment_tarn: item?.dispatch_tarn,
              u_dispatch_status: item?.dispatch_status,
              u_case_number: item?.number,
            };
          }),
        };
      } else {
        const data = response?.data?.result?.tickets?.recently_closed?.find(
          (item: any) => {
            if (
              item?.appointment_end &&
              checkIsUpcommingAppointment(item?.appointment_end)
            ) {
              return item;
            }
          }
        );
        if (!data) {
          return [];
        }
        return {
          result: [data]?.map((item: any) => {
            return {
              u_appointment_end: item?.appointment_end, //"03/12/2024 17:00",
              number: item?.number,
              u_technician_name: item?.technician_name || "",
              u_appointment_start: item?.appointment_start,
              u_reservation_number: item?.reservation_id,
              // u_appointment: "ccdd93c71bf4ce10cedec998624bcb47",
              u_service_instance_id: item?.service_instance_id,
              u_appointment_tarn: item?.dispatch_tarn,
              u_dispatch_status: item?.dispatch_status,
              u_case_number: item?.number,
            };
          }),
        };
      }
    } catch (error) {
      throw error;
    }
  }
);

const repairAppointmentDetailSlice = createSlice({
  name: "repairAppointment/details",
  initialState,
  reducers: {
    repairAppointmentDetailSliceSetInitialState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getRepairAppointmentDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRepairAppointmentDetails.fulfilled, (state, action) => {
      state.repairAppointmentDetails = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getRepairAppointmentDetails.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "burriedDrop details",
        action.error?.message,
        action?.type
      );
      state.repairAppointmentDetails = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const { repairAppointmentDetailSliceSetInitialState } =
  repairAppointmentDetailSlice.actions;
export default repairAppointmentDetailSlice.reducer;
