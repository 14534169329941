import { useState, useEffect } from "react";
import ApiService from "../utils/ApiService";
import { getEnvironment } from "../utils/EnvConfig";

const useFetchLogin = (params = {}) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    getResponse();
  }, []);
  const getResponse = async () => {
    const env = getEnvironment();
    ApiService.get({ ...params, apiUrl: env.customAPIUrl }, {}, {}).then(
      (result) => {
        setData(result.data);
      }
    );
  };
  return [data];
};

// return promise verify username
export const verifyUserName = async (params = {}, body = {}) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.customAPIUrl },
    body,
    {}
  );
};

// return promise verify password
export const verifyPassword = async (params = {}, body = {}) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.customAPIUrl },
    body,
    {}
  );
};

// return promise with the token for validate user name before login
export const getTokenForValidateUserName = async (
  params = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.get(
    { ...params, apiUrl: env?.brightspeedApi },
    { headers: headers }
  );
};

// return promise with validate user name before login
export const validateUserName = async (params = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.get(
    { ...params, apiUrl: env?.validateUserIAmAPIUrl },
    headers
  );
};

export const getAzureInfo = async (params = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.get(
    { ...params, apiUrl: env?.brightspeedApi },
    { headers: headers }
  );
};

// return promise login API which return token and refresh token
export const login = async (body = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.post({ apiUrl: env?.IAM_login_url }, body, headers);
};

export const invalidateTokens = async (params = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env?.IAM_invalidate_RefreshTokens },
    {},
    headers
  );
};

export const setPassword = async (params = {}, body = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.patch(
    { ...params, apiUrl: env?.validateUserIAmAPIUrl },
    body,
    headers
  );
};

export const resetPasswordConfirmation = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env?.brightspeedApi },
    body,
    headers
  );
};

export const forgotPassword = async (params = {}, body = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env?.brightspeedApi },
    body,
    { headers: headers }
  );
};

export const forgotUsername = async (params = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.get(
    { ...params, apiUrl: env?.brightspeedApi },
    { headers: headers }
  );
};

export const validateOTP = async (params = {}, body = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env?.brightspeedApi },
    body,
    { headers: headers }
  );
};

export const resendOTP = async (params = {}, body = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env?.brightspeedApi },
    body,
    { headers: headers }
  );
};

export const registerUser = async (params = {}, body = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env?.brightspeedApi },
    body,
    { headers: headers }
  );
};

export const getTokenForBrightspeedAPI = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.post({ ...params, apiUrl: env?.brightspeedApi });
};

export const getDecryptionQstr = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env?.brightspeedApi },
    body,
    { headers: headers }
  );
};

export const getEncryptionQstr = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env?.brightspeedApi },
    body,
    { headers: headers }
  );
};

export const generateSessionId = async (params = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.get(
    { ...params, apiUrl: env?.sessionManagement },
    { headers: headers }
  );
};

export const generateJWToken = async (params = {}, headers = {}) => {
  return await ApiService.get(
    { ...params, apiUrl: "https://apgx.brightspeed.com/cc-jws-generator" },
    { headers: headers }
  );
};

export const encryption = async (params = {}, body = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env?.encryptionUrl },
    body,
    { headers: headers }
  );
};
export const dwLogging = async (params = {}, body = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.brightspeedApi },
    body,
    { headers: headers }
  ).then((res) => console.log("loggin response", res));
};
export const setLockUnlockStatus = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env?.brightspeedApi },
    body,
    { headers: headers }
  );
};

export const getUserAccNoAndZipcode = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.brightspeedApi },
    body,
    { headers: headers }
  );
};

export const notifyToRegisterUser = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env?.brightspeedApi },
    body,
    { headers: headers }
  );
};
export default useFetchLogin;
