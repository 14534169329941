import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import useFetchAEMContent from "../../../../shared/hooks/useFetchAemContents";
import { errorConditionGTM } from "../../Utils/googleTags";

type quickBillPayPageContentInitialStateType = {
    quickBillPayPageContent: any;
    loading: boolean;
    error: any;
    domain: any;
};
const initialState: quickBillPayPageContentInitialStateType = {
    quickBillPayPageContent: null,
    loading: false,
    error: null,
    domain:window.location.host
};

export const getquickBillPayPageContent = createAsyncThunk(
    "get/quick-bill-pay/content",
    async () => {
        try {
            const response = await useFetchAEMContent(
                {
                    "": `content/dam/consumer-app/account-management/entry/quick-bill-pay.json?appName=${initialState.domain}`,
                },
            );
            return response?.data;
        } catch (error) {
            throw error;
        }
    }
);

const getquickBillPayContentDetailSlice = createSlice({
    name: "quick-bill-pay/Details",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getquickBillPayPageContent.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getquickBillPayPageContent.fulfilled, (state, action) => {
            state.quickBillPayPageContent = action.payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getquickBillPayPageContent.rejected, (state, action) => {
            errorConditionGTM(
                "BRSPD_Fiber_EC_Flow",
                "quick bill pay contentstack",
                action.error?.message,
                action?.type
            );
            state.quickBillPayPageContent = null;
            state.loading = false;
            state.error = action.error;
        });
    },
});

export default getquickBillPayContentDetailSlice.reducer;