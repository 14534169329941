import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import fetchContent from "../../../../shared/hooks/useFetchContent";
import {
  VACATION_PAUSE_SERVICE,
  contentApiHeader,
  contentApiParams,
} from "../../../../shared/utils/Constants";
import { errorConditionGTM } from "../../Utils/googleTags";

type vacationPauseServiceDetailsInitialStateType = {
  vacationPauseServicePageContent: any;
  loading: boolean;
  error: any;
};
const initialState: vacationPauseServiceDetailsInitialStateType = {
  vacationPauseServicePageContent: null,
  loading: false,
  error: null,
};

export const getVacationPauseServicePageContent = createAsyncThunk(
  "get/vacationPauseService-page/content",
  async () => {
    try {
      const response = await fetchContent(
        contentApiParams(VACATION_PAUSE_SERVICE),
        contentApiHeader
      );
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

const vacationPauseServiceDetailSlice = createSlice({
  name: "vacationPauseService-page/Details",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getVacationPauseServicePageContent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getVacationPauseServicePageContent.fulfilled,
      (state, action) => {
        state.vacationPauseServicePageContent = action.payload;
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(
      getVacationPauseServicePageContent.rejected,
      (state, action) => {
        errorConditionGTM(
          "BRSPD_Fiber_EC_Flow",
          "Vacation pause service conetentstack",
          action.error?.message,
          action?.type
        );
        state.vacationPauseServicePageContent = null;
        state.loading = false;
        state.error = action.error;
      }
    );
  },
});

export default vacationPauseServiceDetailSlice.reducer;
