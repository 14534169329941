import { useState, useEffect } from "react";
import ApiService from "../utils/ApiService";
import { getEnvironment } from "../utils/EnvConfig";
//This hook will be deleted in future
const useFetchPaymentHistory = (params = {},header={}) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    getResponse();
  }, []);
  const getResponse = async () => {
    const env = getEnvironment();
    ApiService.get({ ...params, apiUrl: env.brightspeedApi },{headers:header}).then(
      (result) => {
        setData(result.data);
      }
    );
  };
  const reload = async () => {
    await getResponse();
  };
  return [data, reload];
};

// return promise
export const fetchPaymentHistory = async (params = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.get(
    { ...params, apiUrl: env.brightspeedApi },
    { headers: headers }
  );
};


export const fetchPaymentHistoryForPA = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.brightspeedApi },
    body,
    { headers: headers }
  );
};
export default useFetchPaymentHistory;




