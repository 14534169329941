import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { add, getMonth, getYear, format } from "date-fns";
import { reFetchListOfPaymentMethods } from "../../../../shared/hooks/useFetchPaymentMethod";
import API_URLS from "../../../../shared/utils/ApiUrls";
import { errorConditionGTM } from "../../Utils/googleTags";
import store from "../store";
import { object } from "yup";

type paymentMethodsInitialStateType = {
  paymentMethods: any;
  loading: boolean;
  error: any;
  nextCreditCardExpirationDate: string;
  nextCreditCardExpirationAllDetail: any;
  addEditPaymentMethodLoading: boolean;
  isListOfPaymentMethodDWLogCalled: boolean;
  paymentMethodAddSection: boolean;
  autoPaymentMethods: any;
  openAutoPayAlertModal: boolean;
  openAutoPayConfirmCVVModal: boolean;
  nextPrimaryPaymentMethod: any;
  selectDeletePrimaryPaymentMethod: any;
  autoPaymentMethodForEdit: any;
  showEditPaymentPopUp: boolean;
  editPrepayCardId: any;
};

const initialState: paymentMethodsInitialStateType = {
  paymentMethods: null,
  loading: false,
  error: null,
  nextCreditCardExpirationDate: "",
  nextCreditCardExpirationAllDetail: null,
  addEditPaymentMethodLoading: false,
  isListOfPaymentMethodDWLogCalled: false,
  paymentMethodAddSection: false,
  autoPaymentMethods: null,
  openAutoPayAlertModal: false,
  openAutoPayConfirmCVVModal: false,
  nextPrimaryPaymentMethod: {},
  selectDeletePrimaryPaymentMethod: {},
  autoPaymentMethodForEdit: {},
  showEditPaymentPopUp: false,
  editPrepayCardId:"",
};

export const getlistOfPaymentMethods = createAsyncThunk(
  "paymentMethods/list",
  async (billingAccountNumber: string) => {
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const response = await reFetchListOfPaymentMethods(
        {
          [API_URLS?.listPaymentMethods_QA_Url]: billingAccountNumber,
        },
        headers
      );

      if(!response?.data?.defaultPaymentMethodId && response?.data?.creditcard?.length > 0) {
        response.data.defaultPaymentMethodId = response?.data?.creditcard[0]?.id;
      }
      if (response?.data?.creditcard) {
        const sortedCreditCardResponse = response?.data?.creditcard?.sort(
          (a: any, b: any) =>
            a.id === response?.data?.defaultPaymentMethodId ? -1 : 1
        );
        response.data.creditcard = sortedCreditCardResponse;
      }

      if (
        (!response?.data?.creditcard ||
          response?.data?.creditcard?.length === 0) &&
        (!response?.data?.paypal || response?.data?.paypal?.length === 0)
      ) {
        response.data.disableChangePlanFeature = true;
      }
      if (
        response?.data?.creditcard?.length > 0 &&
        reduxStore?.user?.data?.billingType?.toUpperCase() !== "POSTPAID" &&
        headers?.siteId?.toUpperCase() === "CTL"
      ) {
        const isContainPaypalCard = response?.data?.creditcard?.find(
          (item: any) => item?.type?.toUpperCase() === "PAYPAL"
        );
        if (isContainPaypalCard?.id) {
          response.data.isContainPaypalCard = true;
        }
      }
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

const checkAndGetCreditCardExiration = (listOfPaymentMethodRes: any) => {
  const nextMonthDate = add(new Date(), { months: 3 });
  const month = getMonth(nextMonthDate);
  const currentMonthInActualNumber = month + 1;
  const nextMonthsYear = getYear(nextMonthDate);
  const nextMonthsYearCTL = parseInt(nextMonthsYear.toString().substr(-2)); //Speed pay year 2 digit `yy`
  const currentYear = getYear(new Date());
  const currentYearCTL = parseInt(currentYear.toString().substr(-2)); //Speed pay year 2 digit `yy`
  if (currentMonthInActualNumber && nextMonthsYear) {
    const nextMonthCardWillExp = listOfPaymentMethodRes?.creditcard?.sort((a:any,b:any)=>{ return a?.expirationYear - b?.expirationYear || a?.expirationMonth - b?.expirationMonth;})?.find(
      (card: any) => {
        if (
          card?.expirationMonth <= currentMonthInActualNumber &&
          ((card?.expirationYear === nextMonthsYear ||
              card?.expirationYear === currentYear)||(card?.expirationYear === nextMonthsYearCTL ||
                card?.expirationYear === currentYearCTL))
        ) {
          return card;
        }
      }
    );
    if (nextMonthCardWillExp?.expirationMonth) {
      return {
        creditCardExpirationDate: format(
          new Date(
            `${nextMonthCardWillExp.expirationMonth}-1-${nextMonthCardWillExp.expirationYear}`
          ),
          "MMMM yyyy"
        ),
        nextMonthCreditCardExpDetails: nextMonthCardWillExp,
      };
    }
  }
};

const paymentMethodsSlice = createSlice({
  name: "paymentMethods",
  initialState,
  reducers: {
    hideCreditCardExpNotification: (state) => {
      state.nextCreditCardExpirationDate = "";
      state.nextCreditCardExpirationAllDetail = null;
    },
    changePrimaryPaymentMethod: (state, action: PayloadAction<string>) => {
      state.paymentMethods = {
        ...state.paymentMethods,
        defaultPaymentMethodId: action.payload,
      };
    },
    onAddEditPaymentMethodLoadingStart: (state) => {
      state.addEditPaymentMethodLoading = true;
    },
    onAddEditPaymentMethodLoadingStop: (state) => {
      state.addEditPaymentMethodLoading = false;
    },
    setIsListOfPaymentMethodDWLogCalled: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isListOfPaymentMethodDWLogCalled = action.payload;
    },
    clearPaymentMethodsSlice: (state) => {
      return initialState;
    },
    setPaymentMethodAddSection: (state, action: PayloadAction<boolean>) => {
      state.paymentMethodAddSection = action.payload;
    },

    addAutoPaymentMethod: (state, action: PayloadAction<string>) => {
      let newState = { ...state.autoPaymentMethods };
      if (
        newState &&
        newState.creditcard &&
        action?.payload.hasOwnProperty("cardNumber")
      ) {
        newState.creditcard = [...newState.creditcard, action.payload];
      } else if (
        newState &&
        newState.bankAccount &&
        action?.payload.hasOwnProperty("bankAccountNumber")
      ) {
        newState.bankAccount = [...newState.bankAccount, action.payload];
      } else if (action?.payload.hasOwnProperty("bankAccountNumber")) {
        newState = {
          ...state.autoPaymentMethods,
          bankAccount: [action.payload],
        };
      } else {
        newState = {
          ...state.autoPaymentMethods,
          creditcard: [action.payload],
        };
      }
      state.autoPaymentMethods = newState;
    },
    editAutoPaymentMethod: (state, action: PayloadAction<any>) => {
      let newState = { ...state.autoPaymentMethods };
      if (
        newState &&
        newState.creditcard &&
        action?.payload.paymentType == "card"
      ) {
        const index = newState.creditcard.findIndex(
          (card: any) => action?.payload?.cardDetails?.id == card?.id
        );

        newState.creditcard[index].streetaddress =
          action?.payload?.cardDetails?.streetaddress;
        newState.creditcard[index].city = action?.payload?.cardDetails?.city;
        newState.creditcard[index].state = action?.payload?.cardDetails?.state;
        newState.creditcard[index].zipCode =
          action?.payload?.cardDetails?.zipCode;
        newState.creditcard[index].expirationMonth =
          action?.payload?.cardDetails?.expirationMonth;
        newState.creditcard[index].expirationYear =
          action?.payload?.cardDetails?.expirationYear;
        newState.creditcard[index].securityCode =
          action?.payload?.cardDetails?.securityCode;
      } else if (
        newState &&
        newState.bankAccount &&
        action?.payload.paymentType == "bankAccount"
      ) {
        const index = newState.bankAccount.findIndex(
          (bankDetails: any) =>
            action?.payload?.bankDetails?.id == bankDetails?.id
        );

        newState.bankAccount[index].bankAccountType =
          action?.payload?.bankDetails?.bankAccountType;
        newState.bankAccount[index].bankAccountNumber =
          action?.payload?.bankDetails?.bankAccountNumber;
        newState.bankAccount[index].routingNumber =
          action?.payload?.bankDetails?.routingNumber;
        newState.bankAccount[index].accountUserName =
          action?.payload?.bankDetails?.accountUserName;
      }
      state.autoPaymentMethods = newState;
    },
    deleteAutoPaymentMethod: (state, action: PayloadAction<any>) => {
      let newState = { ...state.autoPaymentMethods };
      // console.log(state.a utoPaymentMethods,"autoPaymentMethodsautoPaymentMethods")
      if (action.payload) {
        newState = {
          ...newState,
          creditcard: state.autoPaymentMethods.creditcard.filter(
            (data: any, index: string) => data.id !== action.payload
          ),
        };
      }
      state.autoPaymentMethods = newState;
    },
    deleteBankPaymentMethod: (state, action: PayloadAction<any>) => {
      let newState = { ...state.autoPaymentMethods };
      //console.log(state.autoPaymentMethods,"autoPaymentMethodsautoPaymentMethods")
      if (action.payload) {
        newState = {
          ...newState,
          bankAccount: state.autoPaymentMethods.bankAccount.filter(
            (data: any, index: string) => data.id !== action.payload
          ),
        };
      }
      state.autoPaymentMethods = newState;
    },
    setOpenAutoPayAlertModal: (state, action: PayloadAction<boolean>) => {
      state.openAutoPayAlertModal = action.payload;
    },
    setDefaultAutoPaymentMethodId: (state, action: PayloadAction<string>) => {
      let newState = { ...state.autoPaymentMethods };
      newState = {
        ...state.autoPaymentMethods,
        defaultPaymentMethodId: action.payload,
      };
      state.autoPaymentMethods = newState;
    },
    deleteAllAutoPaymentMethods: (state) => {
      let newState = { ...state.autoPaymentMethods };

      state.autoPaymentMethods = { ...newState, creditcard: [] };
    },
    selectAutoPaymentMethodForEdit: (state, action: PayloadAction<object>) => {
      state.autoPaymentMethodForEdit = action.payload;
    },
    setOpenConfirmCVVModal: (state, action: PayloadAction<boolean>) => {
      state.openAutoPayConfirmCVVModal = action.payload;
    },
    setNextPrimaryPaymentMethod: (state, action: PayloadAction<object>) => {
      // let newState = { ...state.autoPaymentMethods };
      // newState = { ...state.autoPaymentMethods, nextPrimaryPaymentMethod: action.payload };
      //state.autoPaymentMethods=newState;
      state.nextPrimaryPaymentMethod = action.payload;
    },
    seleteDeletePrimaryPaymentMethodFlag: (
      state,
      action: PayloadAction<boolean>
    ) => {
      let newState = { ...state.autoPaymentMethods };
      newState = {
        ...state.autoPaymentMethods,
        deletePrimaryPaymentMethodFlag: action.payload,
      };
      state.autoPaymentMethods = newState;
    },
    setSelectDeletePrimaryPaymentMethod: (
      state,
      action: PayloadAction<object>
    ) => {
      // let newState = { ...state.autoPaymentMethods };
      // newState = { ...state.autoPaymentMethods, nextPrimaryPaymentMethod: action.payload };
      //state.autoPaymentMethods=newState;
      state.selectDeletePrimaryPaymentMethod = action.payload;
    },
    setAutoPaySettingsUpdated: (state, action: PayloadAction<boolean>) => {
      let newState = { ...state.autoPaymentMethods };
      newState = {
        ...state.autoPaymentMethods,
        autoPaySettingsUpdated: action.payload,
      };
      state.autoPaymentMethods = newState;
    },
    setShowEditPaymentPopUp: (state, action:PayloadAction<boolean>)=>{
      state.showEditPaymentPopUp = action.payload;
    },
    setPrepayCardId: (state, action:PayloadAction<any>)=>{
      state.editPrepayCardId = action.payload;
    },
    paymentMethodsSliceSetInitialState: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getlistOfPaymentMethods.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getlistOfPaymentMethods.fulfilled, (state, action) => {
      const nextExpCardDetails = checkAndGetCreditCardExiration(action.payload);
      state.nextCreditCardExpirationDate =
        nextExpCardDetails?.creditCardExpirationDate || "";
      state.nextCreditCardExpirationAllDetail =
        nextExpCardDetails?.nextMonthCreditCardExpDetails || null;
      state.paymentMethods = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getlistOfPaymentMethods.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "payment methods",
        action.error?.message,
        action?.type
      );
      state.paymentMethods = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const {
  hideCreditCardExpNotification,
  changePrimaryPaymentMethod,
  onAddEditPaymentMethodLoadingStart,
  onAddEditPaymentMethodLoadingStop,
  clearPaymentMethodsSlice,
  setIsListOfPaymentMethodDWLogCalled,
  setPaymentMethodAddSection,
  addAutoPaymentMethod,
  deleteAutoPaymentMethod,
  setOpenAutoPayAlertModal,
  setDefaultAutoPaymentMethodId,
  deleteAllAutoPaymentMethods,
  selectAutoPaymentMethodForEdit,
  setOpenConfirmCVVModal,
  setNextPrimaryPaymentMethod,
  seleteDeletePrimaryPaymentMethodFlag,
  deleteBankPaymentMethod,
  setSelectDeletePrimaryPaymentMethod,
  setAutoPaySettingsUpdated,
  editAutoPaymentMethod,
  paymentMethodsSliceSetInitialState,
  setShowEditPaymentPopUp,
  setPrepayCardId,
} = paymentMethodsSlice.actions;
export default paymentMethodsSlice.reducer;
