import ApiService from "../utils/ApiService";
import { getEnvironment } from "../utils/EnvConfig";

export const getAxonToken = async (params = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.get(
    { ...params, apiUrl: env.axon_url },
    { headers: headers }
  );
};

export const getAxonModemDetail = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.brightspeedApi },
    body,
    {
      headers: headers,
    }
  );
};

export const updateModemDetail = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.brightspeedApi },
    body,
    {
      headers: headers,
    }
  );
};
export const rebootModem = async (params = {}, body = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.brightspeedApi },
    body,
    {
      headers: headers,
    }
  );
};
