import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { fetchUpcomingAppointment } from "../../../../shared/hooks/useFetchAppointment";
import API_URLS from "../../../../shared/utils/ApiUrls";
import {
  checkIsPastDate,
  checkIsUpcommingAppointment,
} from "../../../../shared/utils/dateUtils";
import { errorConditionGTM } from "../../Utils/googleTags";
import store from "../store";
import { getServiceAddressFromUser } from "../../Utils/utils";

type upcomingAppointmentInitialStateType = {
  upcomingAppointment: any;
  rescheduleStatus: string;
  loading: boolean;
  error: any;
};
const initialState: upcomingAppointmentInitialStateType = {
  upcomingAppointment: null,
  rescheduleStatus: localStorage.getItem("rescheduling") || "no",
  loading: false,
  error: null,
};

// export const getUpcomingAppointment = createAsyncThunk(
//   "upcoming/appointment",
//   async (user: any) => {
//     try {
//       const { billingAccountNumber, email } = user;
//       const response = await fetchUpcomingAppointment({
//         // [API_URLS?.upComingAppointmentUrl]: `${billingAccountNumber}?email=${email}`,
//         [API_URLS?.fetchPastAppointments]: `?email=${email}`
//       });
//       return response?.data;
//     } catch (error) {
//       throw error;
//     }
//   }
// );

export const getUpcomingAppointment = createAsyncThunk(
  "upcoming/appointment",
  async (user: any) => {
    try {
      const { billingAccountNumber, email, isFromOtherAgent } = user;
      const serviceAddLocal = getServiceAddressFromUser(user);
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const response = await fetchUpcomingAppointment(
        {
          "": `${
            process.env.REACT_APP_ENV === "QA"
              ? API_URLS?.upComingAppointmentUrl_QA
              : API_URLS?.fetchPastAppointments
          }?ban=${billingAccountNumber}&addressId=${
            serviceAddLocal?.addressId
          }`,
        },
        headers
      );
      // console.log("[UA] process.env: ", response?.data);
      const respData = response?.data;
      // const respData: any = [];

      const sortedAppointmentDataForOrderNumber =
        respData?.userAppointments[0]?.order?.sort(
          (a: any, b: any) =>
            new Date(b?.apptDetails?.appointmentDate)?.valueOf() -
            new Date(a?.apptDetails?.appointmentDate).valueOf()
        );
      const latestAppontmentDetails =
        sortedAppointmentDataForOrderNumber?.length > 0
          ? sortedAppointmentDataForOrderNumber?.find(
              (item: any) => item?.orderNumber && item?.orderStatus !== "Canceled"
            ) || ""
          : "";

      let pendingOrderDetails = [];
      const respDataMapped = {
        upcomingAppointments: [
          {
            orderDetails: {
              pendingOrderDetails: respData?.userAppointments[0]?.order.filter(
                (anOrder: any) => {
                  if (
                    !isFromOtherAgent &&
                    anOrder?.installType?.toUpperCase() !== "S" &&
                    checkIsUpcommingAppointment(
                      anOrder?.apptDetails?.appointmentDate
                    ) &&
                    (anOrder?.orderStatus !== "Canceled" || !anOrder?.orderStatus?.includes("Cancel"))
                  ) {
                    return anOrder;
                  } else if (
                    isFromOtherAgent &&
                    (anOrder?.orderStatus !== "Canceled" &&
                    anOrder?.orderStatus !== "Completed" &&
                    anOrder?.orderStatus !== "Cannot Complete"|| 
                    !anOrder?.orderStatus?.includes("Cancel"))
                  ) {
                    return anOrder;
                  }
                }
              ),
              pastAppointment: respData?.userAppointments[0]?.order?.filter(
                (anOrder: any) =>
                  checkIsPastDate(anOrder?.apptDetails?.appointmentDate) ||
                  anOrder?.orderStatus === "Canceled" ||
                  anOrder.orderStatus === "Completed" ||
                  anOrder.orderStatus === "Cannot Complete" ||
                  anOrder?.orderStatus?.includes("Cancel")
              ),
            },
          },
        ],
        orderId: latestAppontmentDetails?.orderNumber || "",
        orderStatusForWallgarden: latestAppontmentDetails?.orderStatus || "",
      };
      // console.log("[UA] response", respDataMapped);
      return respDataMapped;
    } catch (error) {
      console.log("[UA] ERROR", error);
      throw error;
    }
  }
);

const upcomingAppointmentSlice = createSlice({
  name: "upcoming/appointment",
  initialState,
  reducers: {
    clearUpcomingAppointmentSlice: (state) => {
      return initialState;
    },
    setRescheduleStatus: (state, action) => {
      state.rescheduleStatus = action.payload;
      return state;
    },
    upcomingAppointmentSliceSetInitialState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getUpcomingAppointment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUpcomingAppointment.fulfilled, (state, action) => {
      state.upcomingAppointment = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getUpcomingAppointment.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "upcomming appointment",
        action.error?.message,
        action?.type
      );
      state.upcomingAppointment = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const {
  clearUpcomingAppointmentSlice,
  setRescheduleStatus,
  upcomingAppointmentSliceSetInitialState,
} = upcomingAppointmentSlice.actions;
export default upcomingAppointmentSlice.reducer;
