import {
  getNotification,
  updateNotification,
} from "../../../shared/hooks/useFetchNotifications";
import ApiUrls from "../../../shared/utils/ApiUrls";
import { format } from "date-fns";

export const debounce = (func) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 300);
  };
};


export const replaceJSX = (text, find1, replace1, find2, replace2,key="") => {
const output = text.split(find1).flatMap((item) => [item, replace1]);
output?.pop();
if (find2 && replace2 && output[output.length - 1]) {
  const output2 = output[output.length - 1]
    .split(find2)
    .flatMap((item) => [item, replace2]);
  output2?.pop();
  output?.pop();
  return [...output, key==="CCBanner" ? "." : '',...output2];
}
return output;
};

export const isOnlyForBAU = () => {
  const env = getAmHost();
  const ENV_needToEnable = [
    "PROD",
    "BAU_DEV2",
    "BAU_QA2"
  ];
  return env && ENV_needToEnable?.includes(env);
}

export const getAmHost = () => {
  const url = window.location.host;
  switch (url) {
    case "myaccountnextdev2.brightspeed.com":
      return "NEXT_DEV2";
    case "myaccountnextqa.brightspeed.com":
      return "NEXT_QA";
    case "myaccountnextqa2.brightspeed.com":
      return "NEXT_QA2";
    case "myaccountdev2.brightspeed.com":
      return "BAU_DEV2";
    case "myaccountqa1.brightspeed.com":
      return "BAU_QA1";
    case "myaccountqa2.brightspeed.com":
      return "BAU_QA2";
    case "myaccountuat.brightspeed.com":
      return "UAT";
    case "myaccountuat2.brightspeed.com":
      return "UAT2";
    case "myaccount.brightspeed.com":
      return "PROD";
    // case "localhost:3000":
    //   return "BAU_DEV2";
  }
};

export const encryptText = (text, privateKey) => {
  try {
    if (window?.pidCryptUtil && window?.pidCrypt) {
      var key = window?.pidCryptUtil.decodeBase64(privateKey);
      var rsa = new window.pidCrypt.RSA();
      //ASN1 parsing
      var asn = window?.pidCrypt.ASN1.decode(
        window?.pidCryptUtil.toByteArray(key)
      );
      var tree = asn.toHexTree();
      //setting the public key for encryption with retrieved ASN.1 tree
      rsa.setPublicKeyFromASN(tree);
      // Base64 encode and encrypt the string
      var crypted = rsa.encrypt(text);
      return window?.pidCryptUtil.encodeBase64(
        window?.pidCryptUtil.convertFromHex(crypted)
      );
    }
  } catch (error) {
    console.info(error);

    return error;
  }
};

export const onEnterKeyPress = (event, callBackFun) => {
  if (event.key === "Enter") {
    event?.preventDefault();
    if (callBackFun && typeof callBackFun === "function") {
      callBackFun(event);
    }
  }
};

export const toggleOneTrust = () => {
  if (window?.OneTrust) {
    window?.OneTrust?.ToggleInfoDisplay();
  }
};

export const phoneNumberValidation = (e) => {
  if (e.keyCode >= 48 && e.keyCode <= 57) {
    e.target.value = e?.target?.value
      ?.replace(/^(\d{3})(\d{3})(\d+)$/, "($1) $2-$3")
      .substr(0, 13);
  } else {
    if (e.keyCode == 8 || e.keyCode == 46) {
      return false;
    } else {
      e.preventDefault();
      return false;
    }
  }
};

export const phoneNumberValidationMask = (e) => {
  if (!/[0-9 ]/.test(e?.key)) {
    e?.preventDefault();
    return false;
  }
  if (e?.target?.value?.length < 14) {
    e.target.value = e?.target?.value
    ?.replace(/^(\d{3})(\d{3})(\d+)$/, "($1) $2-$3")
    .substr(0, 13);
  }else if (e?.target?.value?.length === 14){
    return true;
  }else {
    e?.preventDefault();
    return false;
  }
};

export const creditCardExpiryAutoFormat = (e) => {
  if (!/[0-9]/.test(e.key)) {
    e.preventDefault();
  }
  if (e.target.value.length < 7) {
    e.target.value = e.target.value
      .replace(
        /^([1-9]\/|[2-9])$/g,
        "0$1/" // To handle 3/ > 03/
      )
      .replace(
        /^(0[1-9]{1}|1[0-2]{1})$/g,
        "$1/" // 11 > 11/
      )
      .replace(
        /^([0-1]{1})([3-9]{1})$/g,
        "0$1/$2" // 13 > 01/3
      )
      .replace(
        /^(\d)\/(\d\d)$/g,
        "0$1/$2" // To handle 1/11 > 01/11
      )
      .replace(
        /^(0?[1-9]{1}|1[0-2]{1})([0-9]{2})$/g,
        "$1/$2" // 141 > 01/41
      )
      .replace(
        /^(([0]{1,})\/|[0]{1,})$/g,
        "0" // To handle 0/ > 0 and 00 > 0
      )
      .replace(
        /[^\d\/]|^[\/]{0,}$/g,
        "" // To allow only numbers and /
      )
      .replace(
        /\/\//g,
        "/" // Prevent entering more than 1 /
      );
    return true;
  }else if(e.target.value.length===7){
    return true;
  } else {
    e.preventDefault();
    return false;
  }
};

export const getCaptchaToken = async (action = "login") => {
  return new Promise((resolve, reject) => {
    window?.grecaptcha?.enterprise.ready(async () => {
      try {
        const token = await window?.grecaptcha?.enterprise?.execute(
          process.env.REACT_APP_RECAPTCHA_KEY,
          {
            action: action,
          }
        );
        if (token) {
          resolve(token);
        } else {
          reject(Error("something went wrong while genererate captcha token"));
        }
      } catch (error) {
        return reject(error);
      }
    });
  });
};

export const repairAppointmentSortedData = (e) => {
  return e.reduce((a, b) => (a.date > b.date ? a : b));
};

export const getFormattedDate = (value) => {
  let date = new Date(value);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export const setNotificationStatusTime = (value, id, type) => {
  let data = value?.notifications?.filter(
    (item) => item.notificationId === id + type
  );
  return data[0]?.createdDate;
};
export const getServiceAddressId = (userAddress) => {
  let tempAddr = "";
  if (userAddress) {
    let { addressId } = userAddress?.find((item) => {
      if (item?.addressType && item?.addressType === "ServiceAddress") {
        return item;
      }
    });
    tempAddr = addressId;
  }
  return tempAddr;
};

export const getServiceAddressFromUser = (user) => {
  return user?.addresses?.find(
    (addr) => addr?.addressType === "ServiceAddress"
  );
};

/* Speed pay encryption */
export const encryptNativeSpeedPay = async (text, publicKey) => {
  const encoder = new TextEncoder();
  try {
    // base64 decode the string to get the binary data
    const binaryDerString = window.atob(publicKey);

    // convert from a binary string to an ArrayBuffer
    const binaryDer = str2ab(binaryDerString);
    const cryptoPublicKey = await window.crypto.subtle.importKey(
      "spki",
      binaryDer,
      { name: "RSA-OAEP", hash: "SHA-256" },
      true,
      ["encrypt"]
    );
    const crypted = await window.crypto.subtle.encrypt(
      cryptoPublicKey.algorithm,
      cryptoPublicKey,
      encoder.encode(text)
    );
    return window.btoa(
      String.fromCharCode.apply(null, new Uint8Array(crypted))
    );
  } catch (error) {
    console.log("Encry", error);
    return await Promise.resolve(text);
  }
};

function str2ab(str) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

export const getDateAndTimeForCTLRescheduleAppoitmentAPI = (date, time) => {
  if (!date || !time) {
    return null;
  }
  return format(new Date(`${date} ${time}`), "yyyy-MM-dd'T'HH:mm:ss");
};

export const getCardNumberWithMasking = (creditCardNumber) => {
  return (
    (creditCardNumber &&
      creditCardNumber?.length > 4 &&
      "****" +
        creditCardNumber?.toString()?.substr(creditCardNumber?.length - 4)) ||
    creditCardNumber
  );
};

export const getBanWithMasking = (ban) => {
  return (
    (ban &&
      ban?.length > 4 &&
      "****" + ban?.toString()?.substr(ban?.length - 4)) ||
    ban
  );
};

export const chatClick = () => {
  if (typeof window.launchChat === "function") {
    window?.launchChat();
  }
};
