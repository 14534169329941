import ApiService from "../utils/ApiService";
import { isAppEnv } from "../utils/CommonUtils";
import { getEnvironment } from "../utils/EnvConfig";

const fetchAccountInfo = async (params = {}, body = {}, headers = {}) => {
  const env = getEnvironment();
  const res = await ApiService.post(
    { ...params, apiUrl: env.brightspeedApi },
    body,
    { headers: headers }
  );
  if(res?.data?.message && !isAppEnv()) {
    throw Error(res?.data?.message || "Account not found");
  }
  return res;
};

export const fetchAccountInfoForAgent = async (params = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.get(
    { ...params, apiUrl: env.brightspeedApi },
    { headers: headers }
  );
};

export const fetchAccountInfoCTL = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.get({ ...params, apiUrl: env.brightspeedApi }, body, {
    headers: headers,
  });
};

export const fetchAccountInfoWithBan = async (params = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.get(
    { ...params, apiUrl: env.brightspeedApi },
    { headers: headers }
  );
};
export const fetchAccountInfoWithBanNZIPCode = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.brightspeedApi },
    body,
    { headers: headers }
  );
};

export const getAccountSummary = async (params = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.get(
    { ...params, apiUrl: env.brightspeedApi },
    headers
  );
};

export const updateAccountInfo = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.put({ ...params, apiUrl: env.brightspeedApi }, body, {
    headers: headers,
  });
};

export const getIAM_Graph_API_Token = async (body = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.post(
    { apiUrl: env.IAM_graphAPI_token },
    body,
    headers
  );
};
export const iamChangePass = async (params = {}, body = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.IAM_graph_API_url },
    body,
    headers
  );
};
export const fetchUserAccountInfo = async (params = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.get(
    { ...params, apiUrl: env.brightspeedApi },
    { headers: headers }
  );
};

export const addAccountPostCall = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.brightspeedApi },
    body,
    { headers: headers }
  );
};

export const userNameValidate = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.brightspeedApi },
    body,
    {
      headers: headers,
    }
  );
};

export const fetchCheckEmail = async (params = {}, body = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.brightspeedApi },
    body,
    { headers: headers }
  );
};

export default fetchAccountInfo;
