import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import useFetchAEMContent from "../../../../shared/hooks/useFetchAemContents";
import { errorConditionGTM } from "../../Utils/googleTags";

type addAccountPageContentInitialStateType = {
  addAccounPageContent: any;
  loading: boolean;
  error: any;
  domain: any;
};
const initialState: addAccountPageContentInitialStateType = {
  addAccounPageContent: null,
  loading: false,
  error: null,
  domain:window.location.host
};

export const getaddAccountPageContent = createAsyncThunk(
  "get/add-Account-page/content",
  async () => {
    try {
      const response = await useFetchAEMContent(
        {
            "":`content/dam/consumer-app/account-management/entry/add-account.json?appName=${initialState.domain}`,
          },
      );
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

const getaddAccountContentDetailSlice = createSlice({
  name: "add-Account-page/Details",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getaddAccountPageContent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getaddAccountPageContent.fulfilled, (state, action) => {
      state.addAccounPageContent = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getaddAccountPageContent.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "Add Account conetentstack",
        action.error?.message,
        action?.type
      );
      state.addAccounPageContent = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default getaddAccountContentDetailSlice.reducer;
