import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import rootReducer from "./rootReducer";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    process.env.REACT_APP_ENV !== "production" && process.env.REACT_APP_ENABLE_LOGS === "true"
      ? getDefaultMiddleware({
          serializableCheck: false,
        })
        .concat(logger)
      : getDefaultMiddleware({
          serializableCheck: false,
        }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
