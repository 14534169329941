import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../hooks";

const PrivateRoutes = () => {
  const token = useAppSelector((state) => state.user.token);
  const logoutClickedValue = useAppSelector(
    (state) => state?.user?.logoutClickedValue
  );
  const { pathname, search } = useLocation();

  if (logoutClickedValue) {
    localStorage.removeItem("redirectPath");
  } else {
    if(pathname?.includes("/plan-details") || pathname?.includes("/update-plan")) {
      localStorage?.setItem("redirectPath", '/account-settings/plan-details');
    } else {
      localStorage?.setItem("redirectPath", pathname + search);
    }
  }
  return token ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
