import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { fetchPaymentHistory, fetchPaymentHistoryForPA } from "../../../../shared/hooks/useFetchPaymentHistory";
import API_URLS from "../../../../shared/utils/ApiUrls";
import { errorConditionGTM } from "../../Utils/googleTags";
import store from "../store";
import ApiUrls from "../../../../shared/utils/ApiUrls";

type paymentHistoryPAInitialStateType = {
  paymentHistory: any;
  loading: boolean;
  error: any;
  fetched: boolean
};
const initialState: paymentHistoryPAInitialStateType = {
  paymentHistory: null,
  loading: false,
  error: null,
  fetched: false
};

export const getPaymentHistoryForPA = createAsyncThunk(
  "payment/historypa",
  async (billingAccountNumber: string) => {
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const param={
        "": ApiUrls?.paymentHistoryUrlForPA_QA,
    }
      const body={
        "ban": billingAccountNumber
      }
      const response = await fetchPaymentHistoryForPA(
        param,
        body,
        headers
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const paymentHistoryForPASlice = createSlice({
  name: "payment/historypa",
  initialState,
  reducers: {
    clearPaymentHistoryForPASlice: (state) => {
      return initialState;
    },
    paymentHistoryForPASliceSetInitialState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getPaymentHistoryForPA.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPaymentHistoryForPA.fulfilled, (state, action) => {    
      state.paymentHistory = action.payload;
      state.loading = false;
      state.error = null;
      state.fetched= true;
    });
    builder.addCase(getPaymentHistoryForPA.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "payment history",
        action.error?.message,
        action?.type
      );
      state.paymentHistory = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const { clearPaymentHistoryForPASlice, paymentHistoryForPASliceSetInitialState } =
  paymentHistoryForPASlice.actions;
export default paymentHistoryForPASlice.reducer;
