//Its' Making issue in Web App side

//import Toast from 'react-native-simple-toast';
//import { Alert } from 'react-native';
// import {localize} from '../locales';
// import {ENV_NAME} from 'react-native-dotenv';

//Its' Making issue in Web App side
// import NetInfo from '@react-native-community/netinfo';

// //import { log } from './LogUtils';

// const showAlert = message => {
//   Alert.alert(
//     // localize('app_name'),
//     'BrightSpeed',
//     message,
//     [
//       {
//         // text: localize('btn_ok'),
//         text: 'OK',
//         onPress: () => {
//           log('OK Pressed');
//         },
//       },
//     ],
//     { cancelable: false },
//   );
// };

// const showToast = message => {
//   return Toast.show(message, Toast.LONG);
// };

import { notificationType } from "../../shared/utils/Constants";

const isAppEnv = () => {
  return !!!window.location;
};

const isInternetConnected = async () => {
  // let state = await NetInfo.fetch();
  // return state.isConnected;
  return true;
};
const replacePlaceholders = (str, values) => {
  let modifiedString = str;
  values.forEach((value, index) => {
    const placeholder = new RegExp(`{{.+?}}`);
    modifiedString = modifiedString.replace(placeholder, value);
  });
  return modifiedString;
};
const generateNotificationId = (type, accountId, ccLastDigits) => {
 
  if (type === "CreditCard") {
    return accountId + ccLastDigits;
  }
  if (type === "VacationSuspend") {
    return accountId + "VacationSuspend";
  }
  if (type === "ACPBanner") {
    return accountId + "ACPBanner";
  }
  if (type === "RoadBore") {
    return accountId + "RoadBore";
  }
  if (type === "BuryCable") {
    return accountId + "buried";
  }
  if (type === "suspend") {
    return accountId + "suspend";
  }
  if (type === "nopaymentmethod") {
    return accountId + "nopaymentmethod";
  }
  if (type === "Initial-login-CTL-User") {
    return accountId + "initialLoginCTLUser";
  }
  if (type === "changePlanPending") {
    return accountId + "changePlanPending";
  }
  if (type === "changePlanPreInstallation") {
    return accountId + "changePlanPreInstallation";
  }
  if(type === "User-Suspended-Vacation"){
    return accountId + "User-Suspended-Vacation";
  }
  if(type === "ReferalBanner"){
    return accountId + "ReferalBanner";
  }
  if(type==="Initial-login-CTL-Welcome"){
    return accountId + "Initial-login-CTL-Welcome"
  }
  if(type==="Download-Banner"){
    return accountId + "Download-Banner"
  }
  if(type==="PaymentArrangements"){
    return accountId + "PaymentArrangements"
  }
  if(type==="ModemRestarted"){
    return accountId + "ModemRestarted"
  }
  if(type==="JeopardyBanner"){
    return accountId + "JeopardyBanner"
  }
  if(type==="AutoPayRemoval"){
    return accountId + "AutoPayRemoval"
  }
  if(type==="ctlpaypaluser"){
    return accountId + "ctlpaypaluser"
  }
  if(type==="qbpBanner"){
    return accountId + "qbpBanner"
  }
};
export {
  isInternetConnected,
  isAppEnv,
  replacePlaceholders,
  generateNotificationId,
};
